<template>
  <div :class="`is-block has-text-${align}`">
    <span class="button is-loading" :class="[size, color]" />
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    size: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'is-white'
    },
    align: {
      type: String,
      default: 'centered'
    }
  }
}
</script>
